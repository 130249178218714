.box1{
    height: 34rem;
    min-height: 100%;
    height: 30rem;
    
    display: block;
    background-color: rgba(17, 24, 39, 0.75); /* Using rgba format */
    margin-right: 40px;
    margin-left: 160px;
    padding: 20px;
}
.box2{
    width: 140%;
    height: 30rem;
    min-height: 100%;
    display: block;
    background-color: rgba(17, 24, 39, 0.75); /* Using rgba format */
    margin-right: 60px;
    margin-left: 140px;
    padding: 20px;
}

.box-text {
    opacity: 1; 
}

.container 
{
    display: flex;
    justify-content: space-between; /* Align items with space between */
}

.h1
{
    color: #5ab963;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 30px;
}
.button
{
    background-color:#5ab963;
    border-radius: 4px;
    padding: 5px 5px;
    font-weight: 800;
    color: white;
    height: 9%;
    width: 50%;
    line-height: 20px;
    

}

.h4
{
    color: darkgray;
    
    padding-left: 10px;
    padding-right: 10px;
    font-family: Libre Franklin, sans-serif;
    line-height: 20px;

}

/* .img {
    width: 100vw;
    background: url("/images/Our-services/nastuh-abootalebi-eHD8Y1Znfpk-unsplash.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

.styled-text {
    color: #fff;
    text-align: center;
    font-family: Libre Franklin, sans-serif;
    font-size: 32px;
    font-weight: 800;
    
}
  