@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-slide {
    animation: slide 20s infinite linear;
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-145%);
    }
  }
}

.logo-slider {
  position: relative;
  overflow: hidden;
  background-color: white;
  padding: 1rem 0;
  max-width: 100%;
}

.logo-track {
  display: flex;
  animation: scroll 20s linear infinite;
}

.logo {
  height: 4rem;
  margin: 0 1rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
