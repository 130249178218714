/* 
@media screen and (max-height : 760px) {
    .extra_spacing{
        margin-bottom:5rem;
    }
}

@media screen and (max-height : 701px) {
    .extra_spacing{
        margin-bottom:10rem;
    }
}


@media screen and (max-height : 503px) {
    .extra_spacing{
        margin-bottom:20rem;
    }
} */
/* pb-[4rem] pt-[1rem] w-[35%] h-[fit-content] bg-white absolute top-0 right-0  */



@media screen and (max-width : 1024px) {
    .extra_spacing{
        display: flex !important;
        justify-content: center !important;
        align-items: center;
    }
    /* .classical{

        top:15rem;
        bottom: 0rem;
        width: 100%;

    } */
    .classical2{
        /* position: relative; */
        height: 100%;

    }
    
}


/* @media screen and (max-width : 640px) {
    .classical{
        top:32vh;
        bottom: 0rem;
        width: 100%;
    }
}

@media screen and (max-width : 285px) {
    .extra_spacing{
        margin-bottom: 5rem;
    }
}

@media screen and (max-width : 363px) {
    .extra_spacing2{
        margin-bottom: 5rem;
    }
}

@media screen and (max-width : 280px) {
    .extra_spacing2{
        margin-bottom: 11rem;
    }
} */