.active{
    border:4px solid #5ab963;
    border-radius: 10px;
}

.not-so-active{
    border:4px solid transparent;
    border-radius: 10px;

}

.classicalProblem > div{
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.activate{
    border:4px solid #e09321;
    border-radius: 10px;
}

/* Hide scrollbar for thumbnails */
/* Right Gradient Overlay */
.right-gradient-overlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px; /* Adjust width based on how strong the effect should be */
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent); /* Dark gradient */
    pointer-events: none;
  }
  
  /* Scroll Indicator Icon */
  .scroll-indicator-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Position it inside the gradient area */
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #5ab963; /* Icon color */
    opacity: 0.8;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  .video-responsive {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* Adjust if needed */
  }
  
  .video-responsive iframe,
  .video-responsive embed,
  .video-responsive object,
  .video-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }