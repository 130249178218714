.block
{
    width: 100%;
    max-width: 1440px;
    margin-top: 0;
    margin-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 10px;
}
.h3
{
    font-family: "Libre Franklin", sans-serif;
    margin-top: 40px;
    font-size: 24px;
    line-height: 36px;
    color:black;
    font-weight: bold;
    
}
.h5
{
    font-family: "Libre Franklin", sans-serif;
    color: darkgray;
    font-size: 24px;
    line-height: 36px;
    
}
