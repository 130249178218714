.navbarDropDown {
    position: absolute;
    top: 100%;
 /* Adjust as needed */
    z-index: 5;
    transition: transform 0.3s ease-in-out;
  }
  
  .slide-down {
    transform: translateY(0);
  }
  
  .slide-up {
    transform: translateY(-100%);
  }
  



  /* Navbar.css */

/* Dropdown Animation (optional if you prefer slide animations) */
.navbarDropDown {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

/* Optional: If you prefer slide animations */
.slide-down {
  max-height: 500px; /* Adjust as needed */
}

.slide-up {
  max-height: 0;
}

/* Dropdown Menu Styling */
.navbarDropDown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbarDropDown li {
  border-bottom: 1px solid #ffffff;
}

.navbarDropDown a,
.navbarDropDown button {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.navbarDropDown a:hover,
.navbarDropDown button:hover {
  background-color: #4b9e53; /* Sea Green Hover */
  color: white;
}

/* Root Variables for Colors */
:root {
  --medium-sea-green: #5ab963;
  --sea-green: #4b9e53;
}

/* Shadow for Dropdowns */
.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

/* Additional Styling as Needed */

