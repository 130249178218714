

@media screen and (max-width : 500px) {
    .first{
        font-size: 18px;

    }
    .second{
        font-size: 54px;

    }
}
@media screen and (max-width : 378px) {
    .first{
        font-size: 12px;

    }
    .second{
        font-size: 36px;

    }
}

