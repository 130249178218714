.bottom-navigation-container{
    height: fit-content;
}

/* bottomNavbar.css */
.special-link {
    margin-top: -0.6rem; /* Adjust this value to move it up */
  }
  

  .custom-logo-height {
    height: 100%;
  }
  

  /* bottomNavbar.css */

.bottom-navigation-container {
  background-color: #0a0e1a;
  width: 100%;
  padding-bottom: 150px;
  padding-top: 90px;
  z-index: 50;
}

/* Ensure the body takes full height */
html, body {
  height: 100%;
  margin: 0;
}

/* Main content layout to ensure space for content and navbar */
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the content takes up the full viewport height */
}

/* Content area should grow to push navbar down when needed */
.content {
  flex-grow: 1;
}