

.blog-writing-container {
  background-color: #f9f9f9;
  padding: 0 0 2rem 0;
}

.form-container {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .form-container {
    padding: 1rem;
  }
}


