.active{
    border:4px solid #5ab963;
    border-radius: 10px;
}

.not-so-active{
    border:4px solid transparent;
    border-radius: 10px;

}

.classicalProblem > div{
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.activate{
    border:4px solid #e09321;
    border-radius: 10px;
}