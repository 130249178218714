@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --medium-sea-green: #5ab963;
    --dark-grey: #949995;
    --transparent: rgba(0, 0, 0, 0);
    --black: var(--white);
    --color-2: #000;
    --bg-grey: #fafafa;
    --white: white;
    --dark-grey-2: #999;
    --sea-green: #48944f;
    --libre: "Libre Franklin", sans-serif;
    --color: white;
    
}


*{
    font-family: "Libre Franklin", sans-serif;
}


.dark-header{
    margin-left: 30px;
    font-weight: 800;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
}

.light-header{
    margin-left: 30px;
    font-weight: 300;
    font-style: normal;
    font-size: 22px;
    line-height: 36px;
    color: #949995;
}

.f-section{
    max-width: 1440px;
}

  
/*  
body{-webkit-user-select:none;-moz-user-select:-moz-none;
    -ms-user-select:none;user-select:none}
     */

button, a, .draggable, .non-selectable {
    user-select: none;
}