.heading {
    font-family: Libre Franklin, sans-serif;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
}
.paragraph {
    color: var(--dark-grey);
    text-align: justify;
    font-family: Libre Franklin, sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
}
h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 38px;
    font-weight: bold;
    line-height: 44px;
}
.subheading{
    font-family: Libre Franklin, sans-serif;
    font-size: 24px;
    line-height: 36px;
    color:var(--medium-sea-green);
}